<template>
   <div class="col-xl-6 px-1 ps-xl-0 mb-1" ref="receita">
      <div class="card mb-0" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-sm-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ receita.nome }}</h1>
                     <p class="font-10 mb-0 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ receita.id }}</p>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar receitas')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Receita',
   props: ['receita', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Automação | Editar receitas')) {
            this.$emit('editar', this.receita)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir receita?',
            text: 'A Receita será excluída, essa ação é irreversível.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'automacao/deleteReceita',
                  params: {
                     id: this.receita.id
                  }
               }).then(() => {
                  this.$refs.receita.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Receita excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>